<style lang="scss" scoped>
@import "./new-client.modal";

.modal-mask {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-content-container {
  background-color: white;
  min-width: 105vw;
  border-radius: 12px;
}

.user-management__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}

.user-management__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 32px;
}

.closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}

.page-section-select-container {
  border-bottom: 0.5px solid #E5E5E5;
  display: flex;
  align-items: flex-end;
  margin: 0em 2em;
}

.page-section-select-size {
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 7.5vh;
  min-width: 12vw;
  padding: 0 1vw;
  display: flex;
  align-items: center;
  margin-right: 2vw;
}

.page-section-select-size:hover {
  background-color: #fdf0e9;
}

.page-section-select-size-active {
  cursor: default;
  border-radius: 8px 8px 8px 8px;
  margin-top: 20px;
  background-color: #fdf0e9;
}

.page-section-select-text {
  font-weight: 600;
  font-size: 1.1em;
  color: #202020;
  margin-left: 1vw;
}

.page-section-select-size:hover>.page-section-select-text {
  color: var(--primary-color);
}

.active-icon {
  color: var(--primary-color) !important;
}

.desactive-icon {
  color: var(--primary-color);
}

.active div {
  color: var(--primary-color);
}

.active {
  background-color: #fdf0e9;
}

.action-buttons {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em;
}

.action-button-wrapper {
  margin-left: 10px;
}

.user-management-modal-body-container{
  padding-bottom: 5vh;
  padding-top: 1vh;
}

.user-management-modal-body {
  height: 95vh;
  overflow: auto;
}
.cnpj-check-container{
  margin-left: 1.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1vw;
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  span{
    color: gray;
  }
}

@media only screen and (max-width: 700px) {
  .button-containear {
    width: 35%;
  }
}

@media only screen and (max-width: 500px) {
  .modal-content-container {
    width: 115vw;
  }

  .button-containear {
    width: 45%;
  }

  .page-section-select-container {
    flex-direction: column;
  }

  .page-section-select-size {
    width: 100%;
    font-size: 1.7em;
  }
}
</style>
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="user-management__header-container">
        <span class="user-management__header-title">
          Editar Cliente
        </span>
        <span @click="closeDialog" class="material-icons closebtn-icon">
          cancel
        </span>
      </div>
      <div class="user-management-modal-body-container">
        <div class="user-management-modal-body">
          <div v-if="id" class="page-section-select-container">
            <div class="page-section-select-size" :class="isActiveContainer('store-info')"
              v-on:click="changeActiveTab('store-info')">
              <span class="material-icons-outlined desactive-icon" :class="isActiveIcon('store-info')">
                settings
              </span>
              <div class="page-section-select-text">
                Informações da Empresa
              </div>
            </div>
            <div class="page-section-select-size" :class="isActiveContainer('headquarters-info')"
              v-on:click="changeActiveTab('headquarters-info')">
              <span class="material-icons desactive-icon" :class="isActiveIcon('headquarters-info')">
                person
              </span>
              <div class="page-section-select-text">
                Informações da Matriz
              </div>
            </div>
            <div class="page-section-select-size" :class="isActiveContainer('client-info')"
              v-on:click="changeActiveTab('client-info')">
              <span class="material-icons-round desactive-icon" :class="isActiveIcon('client-info')">
                storefront
              </span>
              <div class="page-section-select-text">
                Informações do Cliente
              </div>
            </div>
          </div>
          <div v-show="client">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form action="" v-if="client && !loading" @submit.prevent="handleSubmit(save)">
                <div v-if="pageTab == 'store-info'">
                  <ClientFields :resales="resales" :getCnpjInfo="getCnpjInfo" :client="client" :isResaller="isResaleUser"/>
                </div>
                <div v-else-if="pageTab == 'headquarters-info'">
                  <StoreFields :states="states" :allCities="allCities" :client="client" />
                </div>
                <div v-else-if="pageTab == 'client-info'">
                  <AttendanceFields :client="client" :isResaller="isResaleUser" :managers="managers"/>
                </div>
                <div>
                  <AttendanceFields :isResaller="isResaleUser" :managers="managers" :client="client" v-if="!id" />
                  <UserFields :client="client" v-if="!id" />
                  <StoreFields :states="states" :allCities="allCities" :client="client" v-if="!id" />
                </div>
                <div v-if="message" class="col-md-12 col-lg-12">
                  <h4 class="py-4 text-center text-danger">{{ message }}</h4>
                </div>
                <p class="cnpj-check-container" @click="duplicatedCnpj = !duplicatedCnpj">
                  <span v-if="duplicatedCnpj" class="material-icons-outlined">check_box</span>
                  <span v-else class="material-icons-outlined">check_box_outline_blank</span>
                  Cliente com CNPJ duplicado
                </p>
                <div class="action-buttons">
                  <Button class="action-button-wrapper" title="Salvar" :text="'Salvar'"
                    :action="() => { handleSubmit(save) }" />
                  <Button class="action-button-wrapper" title="Cancelar" :text="'Cancelar'" :action="closeDialog" />
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <cc-modal :modal="modal" @close="close">
  		<div slot="body" class="container-fluid pt-0 sellers">
  			<div class="row contents shadow">
  				<div class="col" v-show="client">
                      <ValidationObserver v-slot="{ handleSubmit }">
                          <form action="" v-if="client && !loading" @submit.prevent="handleSubmit(save)">

                              <div class="row d-flex justify-content-center section-header">
                                  <div class="col-12">
                                      <label>Informações da empresa</label>
                                  </div>
                              </div>

                              <ClientFields :client="client" />

                              <div class="row section-header">
                                  <div class="col-12">
                                      <label>Configurações do Cliente</label>
                                  </div>
                              </div>

                              <AttendanceFields :client="client" />

                              <div class="row section-header" v-if="!id">
                                  <div class="col-12">
                                      <label>Informações do usuário</label>
                                  </div>
                              </div>

                              <UserFields :client="client" v-if="!id" />

                              <div class="row section-header">
                                  <div class="col-12">
                                      <label>Informações da matriz</label>
                                  </div>
                              </div>

                              <StoreFields :client="client" />

                              <div class="row pt-2">
                                  <div v-if="message" class="col-md-12 col-lg-12">
                                      <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                      <button class="btnFeedbackModal" type="submit">Salvar</button>
                                  </div>
                              </div>
                          </form>
                      </ValidationObserver>
  				</div>
  			</div>
  		</div>
  	</cc-modal> -->
</template>

<script>

import ClientService from "@/services/v3/client.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClientFields from './_client.fields'
import UserFields from './_user.fields'
import AttendanceFields from './_attendance.fields'
import StoreFields from './_store.fields'
import Button from '@/components/ui/buttons/StandardButton.vue'
import BrasilApi from "../../../services/BrasilApi";
import { mapState } from 'vuex'
import UserService from "@/services/v3/management/user.service";
import UserServiceV1 from "@/services/v1/user.service";


export default {
  mixins: [loaderMixin],
  props: {
    id: {
      type: Number
    },
    registration: {
      type: Object
    },
    closeDialog: {
      type: Function,
      required: true
    }
  },
  components: {
    ClientFields,
    StoreFields,
    UserFields,
    AttendanceFields,
    ValidationProvider,
    ValidationObserver,
    Button,
  },
  computed: {
      ...mapState([ 'user' ])
  },
  data() {
    return {
      pageTab: 'store-info',
      isResaleUser: undefined,
      duplicatedCnpj: false,
      users: [],
      managers: [],
      resales: [],
      allCities: [],
      states: [],
      client: {
        manager: {},
        head_office: {},
        attendance: {},
        resale: {},
        sectors: [],
        order_type: undefined,
        closing_method: undefined,
        settings: [
          { key: 'CLIENT_RELEASE_STATUS' }
        ],
      },
      brasilApi: new BrasilApi(),
      categories: [],
      loading: true,
      message: null,
      stores: [],
      svc: new ClientService(),
      user_svc: new UserService(),
      user_svcV1: new UserServiceV1(),
      modal: {
        title: this.id ? `Editar Cliente` : 'Novo Cliente',
        icon_title: 'fas fa-users',
        cancel_text: 'Voltar',
        style: {
          width: "90%",
          height: "fit-content"
        }
      }
    };
  },
  watch: {
    'client.name': function (val) {
      this.client.head_office.social_reazon = val
      this.client.head_office.name = val
    },
    'client.trading_name': function (val) {
      this.client.head_office.trading_name = val
    },
    'client.email': function (val) {
      if (this.client.manager) {
        this.client.manager.email = val
      }
      this.client.head_office.email = val
    },
    'client.cnpj': function (val) {
      this.client.head_office.cnpj = val
    },
    'client.phone': function (val) {
      this.client.head_office.phone = val
      if (this.client.manager) {
        this.client.manager.phone = val
      }
    },
  },
  methods: {
    load_managers() {
      return new Promise((resolve, reject) => {
            this.user_svc.list().then(response => {
              if (this.isResaleUser){
                response.data.forEach(element => {
                  if (element.name == this.user.user_name) {
                    this.client.attendance.attendant = element
                    this.managers.push(element)
                  }
                });
              } else this.managers = response.data
              this.managers.forEach(element => {
                element.text = element.name
              });
              resolve()
            }).catch(error => {
              reject(error)
            })
      })
			 
    },
    getCnpjInfo (cnpj) {
      this.brasilApi.getCnpjInfo(cnpj).then(resp => {
        this.$set(this.client, 'name', resp.data.razao_social)
        this.$set(this.client, 'trading_name', resp.data.razao_social.replace('LTDA', ''))
        this.$set(this.client, 'group_name', resp.data.razao_social.replace('LTDA', ''))
        this.$set(this.client, 'contact', resp.data.qsa.length > 0 ? resp.data.qsa[0].nome_socio : '')
        this.$set(this.client, 'phone', resp.data.ddd_telefone_1)
        this.$set(this.client, 'head_office', {
          ...this.client.head_office,
          zip_code: resp.data.cep,
          street: resp.data.logradouro + ' ' + resp.data.numero,
          district: resp.data.bairro
        })
        this.states.forEach(element => {
            if( element.value.est_sigla == resp.data.uf){
                this.$set(this.client.head_office, 'state', {...element.value, id:element.value.est_id, name: element.value.est_nome})
                this.allCities.forEach(element2 => {
                    if( element2.est_id == element.value.est_id && element2.cid_nome.toLowerCase() == resp.data.municipio.toLowerCase()){
                        this.$set(this.client.head_office, 'city', {...element2, id: element2.cid_id, name: element2.cid_nome})
                    }
                });
                this.$forceUpdate()
            }
        });
      })
    },
    load_resales() {
      return this.svc.load_resales().then(response => response.data).then(data => {
        if ( this.isResaleUser) {
          const resaleId = localStorage.getItem('revenda')
          data.data.forEach(element => {
            if (element.id == resaleId) {
              this.client.resale = element
              this.resales = [element]
            }
          });
        } else {
          this.resales = data.data
          this.client.resale = this.resales.find(r => r.id == this.client.resale.id)
        }
        this.resales.forEach(element => {
          element.text = element.name
        });
        
      })
    },
    isActiveTab(tab) {
      if (tab == this.pageTab) return true
      return false
    },
    isActiveContainer(tab) {
      if (this.isActiveTab(tab)) return 'page-section-select-size-active'
    },
    isActiveIcon(tab) {
      if (this.isActiveTab(tab)) return 'active-icon'
    },
    changeActiveTab(tab) {
      this.pageTab = tab
    },
    remove(event, user) {
      event.preventDefault()
      event.stopPropagation()
      this.confirm_action({
        message: "Confirma remoção do usuário?",
        subtitle: `O usuário ${user.usu_nome} será removido!`,
        callback: () => {
          this.svc.remove(this.client.cli_id, user).then(() => this.load_users())
        }
      })
      return false
    },
    save() {
      this.client.head_office.cnpj = this.client.cnpj
      this.present_loader("Salvando usuário...")
      this.message = null
      let callback = data => {
        this.dismiss_loader()
        this.close()
        const notification = {
          type: 'success',
          message: `Cliente ${this.id ? 'atualizado' : 'criado'} com sucesso!`
        }
        this.$store.dispatch('notification/add', notification)
        this.$emit("reload");
      }
      let callback_error = err => {
        this.dismiss_loader()
        if (err.response && err.response.data && err.response.data.errors) {
          let errors = err.response.data.errors
          this.message = Object.values(errors).flat()[0]
        } else {
          this.message = 'Ops! Houve um erro ao salvar o usuário, tente novamente mais tarde!'
        }
        ErrorHandlerService.handle(err, this.$store);
      }
      this.client.duplicateIgnore = this.duplicatedCnpj
      if (this.id) {
        this.svc.update(this.client).then(callback, callback_error)
      } else {
        this.svc.create(this.client).then(callback, callback_error)
      }
    },
    add_new() {
      this.toggle_user({ store: this.stores[0], profile: {} })
    },
    close() {
      this.$emit("close");
    },
    load_client() {
      if (this.id) {
        this.present_loader("Carregando usuários...")
        return this.svc.show(this.id).then(response => response.data).then(data => {
          this.client = data
          this.client.head_office = this.client.head_office ? this.client.head_office : {}
          this.dismiss_loader()
        }).catch(error => {
          ErrorHandlerService.handle(error, this.$store);
        });
      } else {
        if (this.registration) {
          this.client = {
            ...this.client,
            registration_id: this.registration.id,
            cnpj: this.registration.cnpj,
            email: this.registration.email,
            resale: this.registration.resale,
            manager: {
              name: this.registration.name,
              password: this.registration.password
            },
            phone: this.registration.phone,
          }
          if (!this.client.order_type) this.client.order_type = '0'
          if (!this.client.closing_method) this.client.closing_method = 'ANALISE_POR_LOJA'
        }
      }
    },
    process_stores() {
      this.users.forEach(u => u.store = this.stores.find(p => p.loj_id == u.usu_idLoja))
      this.$forceUpdate()
    },
    load_region_data() {
        return this.user_svcV1.region_data().then(response => response.data).then(data => {
            this.states = data.estados.map(c => ({ value: c, text: c.est_nome }))
            this.allCities = data.cidades
        })
    },
  },
  async mounted() {
    this.isResaleUser = localStorage.getItem('revenda') > 1
    this.load_region_data()
    await this.load_client()
    await this.load_managers()
    this.load_resales()
    if (this.isResaleUser) {
      this.client.order_type = 0
      this.client.closing_method = 'ANALISE_POR_LOJA'
    }
    this.loading = false
  }
};
</script>
