<style lang="scss" scoped>
	@import "./new-client.modal";

.input-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-container {
  width: 23vw;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}
.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus {
  border: none;
  outline: none;
}
.main-container {
  display: flex;
  flex-direction: column;
  margin: 2em;
}
.selects-input {
  width: 45vw !important;
}
.form-check-input {
  accent-color: #cc6119;
}
.attendance-select-container{
  width: 45vw;
}
.attendance-selects-row{
  display: flex; 
  justify-content: space-between;
}
@media only screen and (max-width: 700px) {
  .attendance-select-container{
    width: 100%;
  }
  .attendance-selects-row{
    flex-direction: column;
    gap: 2vh;
  }
}

</style>
<template>
    <div class="main-container">
      <div class="attendance-selects-row" >

        <!-- <div>
            <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
              Responsável
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <cc-select-v2
                    id="attendant"
                    :tracker="'id'"
                    :text="'name'"
                    :empty="false"
                    v-model="client.attendance.attendant"
                    :options="managers" 
                    class="selects-input"/>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div> -->

        <SelectCheckFiltered title="Responsável" :list="managers"
        :placeholder="client.attendance.attendant ? client.attendance.attendant.name : 'Selecione o responsavel'" 
         :alert="true" class="attendance-select-container" :action="value => client.attendance.attendant = value"/>

        <!-- <div>
            <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
              Status Implantação
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <cc-select-v2
                    id="attendant"
                    :tracker="'id'"
                    :text="'name'"
                    :empty="false"
                    v-model="client.attendance.status"
                    :options="statuses" 
                    class="selects-input"/>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div> -->

        <SelectCheckFiltered title="Status Implantação" :list="statuses"
        :placeholder="client.attendance.status ? client.attendance.status.text : 'Selecione o status'" 
        style="margin-bottom: 2vh;" class="attendance-select-container" :alert="true" :action="value => client.attendance.status = value"/>

      </div>
      <div class="attendance-selects-row" style="justify-content: flex-start; gap: 5vw;">
        <div >
            <!-- <label>Status Implantação:<span style="color:red !important">*</span></label> -->
            <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
              Status Implantação
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <div class="radio-group">
                    <div class="form-check">
                        <input type="radio"
                            id="release-status-one"
                            class="form-check-input"
                            value="1"
                            v-model="client.implanted">
                        <label class="form-check-label" for="release-status-one">
                            Cliente Implantado
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="radio"
                            id="release-status-two"
                            class="form-check-input"
                            value="2"
                            v-model="client.implanted">
                        <label class="form-check-label" for="release-status-two">
                            Captação parcial de Fornecedores
                        </label>
                    </div>
                </div>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div v-if="!client.id">
            <!-- <label>Status Implantação:<span style="color:red !important">*</span></label> -->
            <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
              Notificação via WhatsApp
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <div class="radio-group">
                    <div class="form-check">
                        <input type="radio"
                            id="release-status-one"
                            class="form-check-input"
                            :value="1"
                            v-model="client.notify">
                        <label class="form-check-label" for="release-status-one">
                            Sim
                        </label>
                    </div>
                    <div class="form-check">
                        <input type="radio"
                            id="release-status-two"
                            class="form-check-input"
                            :value="0"
                            v-model="client.notify">
                        <label class="form-check-label" for="release-status-two">
                            Não
                        </label>
                    </div>
                </div>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
      </div>
        
    </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import SelectCheckFiltered from '../../../components/ui/inputs/SelectCheckFiltered.vue';

export default {
	mixins: [ loaderMixin ],
  props: ['client', 'managers', 'isResaller'],
	components: {
		ValidationProvider,
		ValidationObserver,
    SelectCheckFiltered
   	},
  	data() {
		return {
            statuses: [
                { id: 'STARTED', text: 'Iniciado' },
                { id: 'WAITING_FIRST_REQUEST', text: 'Aguardando cotação' },
                { id: 'ON_WATCH', text: 'Em acompanhamento' },
                { id: 'IMPLANTED', text: 'Implantado' },
                { id: 'SUSPENDED', text: 'Suspendido' },
                { id: 'ON_ALERT', text: 'Em alerta' },
            ]
		};
	},
	mounted() {
        this.client.attendance = this.client.attendance || {}
        if (this.isResaller) {
          this.statuses = [{ id: 'STARTED', text: 'Iniciado' }]
          this.client.attendance.status = { id: 'STARTED', text: 'Iniciado' }
        }
	}
};
</script>
