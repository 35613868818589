<style lang="scss" scoped>
@import "./new-client.modal";

.main-container {
  display: flex;
  flex-direction: column;
  margin: 2em;
}

.inputs-wrapper {
  display: flex;
  justify-content: space-between;
}

.input-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}

.input-container {
  width: 45vw;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}

.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}

.input-value:focus {
  border: none;
  outline: none;
}

.selects-wrapper {
  width: 45vw;
}

.activity-selects-wrapper {
  width: 30vw;
}

.form-check-input {
  accent-color: #cc6119;
}
.form-check-input-disabled{
  accent-color: gray
}
.client-select-container{
  width: 45vw;
  margin-bottom: 2vh;
}
.client-select-container2{
  width: 30vw;
  margin-bottom: 2vh;
}
.client-field-sectors-container{
  display: flex;
  gap: 2vw;
  margin-bottom: 2vh;
}
.client-field-sector{
  font-weight: 400;
  font-size: 1.6em;
  color: #898989;
  border-bottom: 1px solid #ff7110;
}

@media only screen and (max-width: 700px) {
  .client-select-container{
    width: 100%;
  }
  .client-select-container2{
    width: 100%;
  }
  .inputs-wrapper {
    flex-direction: column;
  }

  .input-container {
    width: 100%;
  }

  .activity-selects-wrapper {
    width: 100%;
  }

  .selects-wrapper {
    width: 100%;
  }
}
</style>
<template>
  <div class="main-container">
    <div class="inputs-wrapper">
      <div>
        <!-- <label>CNPJ:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          CNPJ
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required|cnpj" v-slot="v">
          <!-- <cc-input type="text" id="client-cnpj" v-mask="'## ### ###/####-##'" v-model="client.cnpj"></cc-input> -->
          <div class="input-container">
            <input type="text" id="client-cnpj" v-mask="'## ### ###/####-##'" v-model="client.cnpj" class="input-value"
              placeholder="Digite o CNPJ" v-on:keyup.enter="cnpjEnter()">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div>
        <!-- <label>Nome do Grupo:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Nome do Grupo
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="group-name" v-model="client.group_name" /> -->
          <div class="input-container">
            <input type="text" id="group-name" v-model="client.group_name" class="input-value"
              placeholder="Digite o nome do grupo">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="inputs-wrapper">
      <div>
        <!-- <label>Email:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Email
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="client-email" v-model="client.email" /> -->
          <div class="input-container">
            <input type="text" id="client-email" v-model="client.email" class="input-value" placeholder="Digite o email">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div>
        <!-- <label>Razão Social:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Razão Social
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="company-name" v-model="client.name" /> -->
          <div class="input-container">
            <input type="text" v-model="client.name" class="input-value" placeholder="Digite a razão social">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="inputs-wrapper">
      <div>
        <!-- <label>Nome Fantasia:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Nome Fantasia
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="client-trading-name" v-model="client.trading_name"></cc-input> -->
          <div class="input-container">
            <input type="text" id="client-trading-name" v-model="client.trading_name" class="input-value"
              placeholder="Digite o nome fantasia">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div>
        <!-- <label>Contato:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Contato
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="client-contact" v-model="client.contact"></cc-input> -->
          <div class="input-container">
            <input type="text" id="client-contact" v-model="client.contact" class="input-value"
              placeholder="Digite o contato">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="inputs-wrapper">
      <div>
        <!-- <label>Site:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Site
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="client-website" v-model="client.site_url"></cc-input> -->
          <div class="input-container">
            <input type="text" id="client-website" v-model="client.site_url" class="input-value"
              placeholder="Digite o site">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div>
        <!-- <label>Telefone:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Telefone
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text" id="client-phone" v-mask="'(##) #.####-####'" v-model="client.phone" /> -->
          <div class="input-container">
            <input type="text" id="client-phone" v-mask="'(##) #.####-####'" v-model="client.phone" class="input-value"
              placeholder="Digite o telefone">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="inputs-wrapper">

      <!-- <div class="selects-wrapper" id="client-resale">
        <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
          Tipo Revenda
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <cc-select-v2 :tracker="'id'" :text="'name'" :empty="false" v-model="client.resale" :options="resales" />
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div> -->

      <SelectCheckFiltered title="Tipo Revenda" :placeholder="client.resale ? client.resale.name : 'Selecione a revenda'" :list="resales" 
       class="client-select-container" :alert="true" :action="value => client.resale = value"/>

      <div class="selects-wrapper">
        <!-- <label>Tipo Entrega:<span style="color:red !important">*</span></label> -->
        <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
          Tipo Entrega
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <div class="radio-group">
            <div class="form-check">
              <input type="radio" id="client-delivery-type-one" class="form-check-input" value="1"
                v-model="client.delivery_type_id">
              <label class="form-check-label" for="client-delivery-type-one">
                CIF
              </label>
            </div>
            <div class="form-check">
              <input type="radio" id="client-delivery-type-two" class="form-check-input" value="2"
                v-model="client.delivery_type_id">
              <label class="form-check-label" for="client-delivery-type-two">
                CIF/FOB
              </label>
            </div>
            <div class="form-check">
              <input type="radio" id="client-delivery-type-three" class="form-check-input" value="3"
                v-model="client.delivery_type_id">
              <label class="form-check-label" for="client-delivery-type-three">
                FOB
              </label>
            </div>
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>

    </div>

    <div class="inputs-wrapper">

      <!-- <div class="activity-selects-wrapper">
        <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
          Ramo de Atividade
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <cc-select-v2 id="client-sector" :tracker="'id'" :text="'name'" :empty="false" :multiple="true"
            v-model="client.sectors" :options="sectors" />
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div> -->

      <SelectCheckFiltered title="Ramo de Atividade" :placeholder="client.sectors.length > 0 ? client.sectors[0].name : 'selecione o ramo'" :list="sectors" 
      class="client-select-container2" :action="value => client.sectors = [value]"/>
        
      
      
      <div class="activity-selects-wrapper">
        <!-- <label>Cotação CX/UN:<span style="color:red !important">*</span></label> -->
        <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
          Cotação CX/UN
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <div class="radio-group">
            <div class="form-check">
              <input type="radio" :disabled="isResaller"  id="client-order-type-one" class="form-check-input" value="1"
                v-model="client.order_type">
              <label class="form-check-label" for="client-order-type-one">
                Pedido Caixa
              </label>
            </div>
            <div class="form-check">
              <input type="radio" id="client-order-type-two" :class="{'form-check-input-disabled': isResaller}" class="form-check-input" value="0"
                v-model="client.order_type">
              <label class="form-check-label" for="client-order-type-two">
                Pedido Unidade
              </label>
            </div>
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="activity-selects-wrapper">
        <!-- <label>Metodo de Fechamento:<span style="color:red !important">*</span></label> -->
        <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
          Metodo de Fechamento
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <div class="radio-group">
            <div class="form-check">
              <input type="radio" id="closing-method-one" :class="{'form-check-input-disabled': isResaller}" class="form-check-input" value="ANALISE_POR_LOJA"
                v-model="client.closing_method">
              <label class="form-check-label" for="closing-method-one">
                Análise por loja
              </label>
            </div>
            <div class="form-check">
              <input type="radio" :disabled="isResaller" id="closing-method-two" class="form-check-input" value="COM_VALOR"
                v-model="client.closing_method">
              <label class="form-check-label" for="closing-method-two">
                Análise por Fornecedor
              </label>
            </div>
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- <div class="client-field-sectors-container">
      <div class="client-field-sector" v-for="(sector, idx) in client.sectors" :key="idx">
        {{ sector.name }}
      </div>
    </div> -->

    <!-- <div v-if="!client.id">
      <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
        Categorias
        <span class="material-icons" style="color: #CB595B;">
          info
        </span>
      </div>
      <ValidationProvider rules="required" v-slot="v">
        <cc-select-v2 id="client-category" :tracker="'id'" :text="'name'" :empty="false" :multiple="true"
          v-model="client.categories" :options="categories" />
        <span class="error">{{ v.errors[0] }}</span>
      </ValidationProvider>
    </div> -->

    <SelectMultiCheckFiltered title="Categorias" v-if="!client.id" placeholder="Selecione a categoria" :list="categories" 
      :alert="true"  :action="value => client.categories = value" :lock="isResaller"/>

  </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClientService from "@/services/v3/client.service";
import CategoryService from "@/services/v3/category.service";
import SelectCheckFiltered from '../../../components/ui/inputs/SelectCheckFiltered.vue';
import SelectMultiCheckFiltered from '../../../components/ui/inputs/SelectMultiCheckFiltered.vue';

export default {
  mixins: [loaderMixin],
  props: ['client', 'getCnpjInfo', 'isResaller', 'resales'],
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectCheckFiltered,
    SelectMultiCheckFiltered
  },
  data() {
    return {
      svc: new ClientService(),
      cat_svc: new CategoryService(),
      categories: [],
      request_types: [],
      sectors: []
    };
  },
  // watch: {
  //   'client.categories': function (val) {
  //     val.forEach(v => {
  //       if (v.id == 0) {
  //         this.client.categories = this.categories.filter(c => c.id != 0)
  //       }
  //     })
  //   },
  // },
  methods: {
    cnpjEnter () {
      let cnpj = this.client.cnpj.replace('/', '')
      cnpj = cnpj.replace('-', '')
      cnpj = cnpj.replace(' ', '')
      cnpj = cnpj.replace(' ', '')
      this.getCnpjInfo(cnpj)
    },
    load_sectors() {
      return this.svc.load_sectors().then(response => response.data).then(data => {
        this.sectors = data
        this.sectors.forEach(element => {element.text = element.name});
        this.client.sectors = this.sectors.map(s => {
          return this.client.sectors.filter(ss => ss.id == s.id)
        }).flat()
      })
    },
    load_categories() {
      return this.cat_svc.list().then(response => response.data).then(data => {
        this.categories = data
        this.categories.forEach(element => {element.text = element.name; element.isCheck = true});
        this.client.categories = data
      })
    }
  },
  mounted() {
    this.load_sectors()
    if (!this.client.id) {
      this.load_categories()
    }
  }
};
</script>
