<style lang="scss" scoped>
@import "./new-client.modal";

.input-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}

.input-container {
  width: 45vw;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}

.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}

.input-value:focus {
  border: none;
  outline: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  margin: 2em;
}

.inputs-wrapper {
  display: flex;
  justify-content: space-between;
}

.select-inputs {
  width: 45vw !important;
}
.store-selects-container{
  width: 45vw;
}

@media only screen and (max-width: 700px) {
  .store-selects-container{
    width: 100%;
  }
  .inputs-wrapper {
    flex-direction: column;
  }

  .select-inputs {
    width: 100% !important;
  }

  .input-container {
    width: 100%;
  }
}
</style>
<template>
  <div class="main-container">
  <div class="inputs-wrapper">

      <!-- <div>
              <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
                UF
                <span class="material-icons" style="color: #CB595B;">
                  info
                </span>
              </div>
              <ValidationProvider rules="required" v-slot="v">
                  <cc-select-v2
                      :tracker="'id'"
                      :text="'name'"
                      :empty="false"
                      :searchable="true"
                      v-model="client.head_office.state"
                      :options="states" 
                      class="select-inputs"/>
                  <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div> -->

      <SelectCheckFiltered title="UF" :list="states" :placeholder="client.head_office.state ? client.head_office.state.name : 'Selecione o estado'" class="store-selects-container"
        :alert="true" :action="setState" />

      <!-- <div>
              <div class="input-title" style="margin-bottom: 5px;" title="Obrigatório">
                Cidade
                <span class="material-icons" style="color: #CB595B;">
                  info
                </span>
              </div>
              <ValidationProvider rules="required" v-slot="v">
                  <cc-select-v2
                      :tracker="'id'"
                      :text="'name'"
                      :empty="false"
                      :searchable="true"
                      v-model="client.head_office.city"
                      :options="available_cities"
                      class="select-inputs" />
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
          </div> -->

      <SelectCheckFiltered title="Cidade" :list="cities" :placeholder="client.head_office.city ? client.head_office.city.name : 'Selecione a cidade'"
        style="margin-bottom: 2vh;" class="store-selects-container" :alert="true" :action="value => client.head_office.city = {id: value.value.cid_id}" />

    </div>
    <div class="inputs-wrapper">
      <div>
        <!-- <label>CEP:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          CEP
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input
                    v-mask="'##.###-###'"
                    type="text"
                      id="store-cep"
                      v-model="client.head_office.zip_code" /> -->
          <div class="input-container">
            <input type="text" id="store-cep" v-mask="'##.###-###'" v-model="client.head_office.zip_code"
              class="input-value" placeholder="Digite o CEP">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div>
        <!-- <label>Endereço:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Endereço
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input
                      id="store-street"
                      type="text"
                      v-model="client.head_office.street" /> -->
          <div class="input-container">
            <input type="text" id="store-street" v-model="client.head_office.street" class="input-value"
              placeholder="Digite o endereço">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="inputs-wrapper">
      <div>
        <!-- <label>Bairro:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Bairro
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text"
                      id="store-district"
                      v-model="client.head_office.district" /> -->
          <div class="input-container">
            <input type="text" id="store-district" v-model="client.head_office.district" class="input-value"
              placeholder="Digite o bairro">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div>
        <!-- <label>Complemento:<span style="color:red !important">*</span></label> -->
        <div class="input-title" title="Obrigatório">
          Complemento
          <span class="material-icons" style="color: #CB595B;">
            info
          </span>
        </div>
        <ValidationProvider rules="required" v-slot="v">
          <!-- <cc-input type="text"
                      id="store-complement"
                      v-model="client.head_office.complement" /> -->
          <div class="input-container">
            <input type="text" id="store-complement" v-model="client.head_office.complement" class="input-value"
              placeholder="Digite o complemento">
          </div>
          <span class="error">{{ v.errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import UserService from "@/services/v1/user.service";
import SelectCheckFiltered from '../../../components/ui/inputs/SelectCheckFiltered.vue';

export default {
  mixins: [loaderMixin],
  props: [
    'client',
    'states',
    'allCities'
  ],
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectCheckFiltered
  },
  data() {
    return {
      user_svc: new UserService(),
      cities: [],
    };
  },
  methods: {
    setState(value) { 
        this.client.head_office.state = value
        this.client.head_office.state.id = value.value.est_id
        this.cities = []
        this.allCities.forEach(element => {
            if (element.est_id == this.client.head_office.state.id) this.cities.push({value: element, text: element.cid_nome})
        });
        this.$forceUpdate()
    },
  },
};
</script>
