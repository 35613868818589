<template>
  <div>
    <div class="input-title">
      {{ title }}
      <span v-if="alert" class="material-icons-outlined input-title-alert">error</span>
    </div>
    <div class="input-containear" v-on:click.stop="openSelect()">
      <input type="text" v-model="stateSearch" class="input-value" :placeholder="placeholder">
      <span v-if="!showSelectBody" class="material-icons input-icon">expand_more</span>
      <span v-else class="material-icons input-icon" v-on:click.stop="closeSelect()">close</span>
    </div>
    <div v-if="showSelectBody" style="position: relative;">
      <div class="select-body period-width">
        <div v-for="value in componentList" v-on:click="toogleCheck(value)" :key="value.text" class="select-body-line">
          <span v-if="value.isCheck" class="material-icons checkbox-icon" :style="getlockCheck()">check_box</span>
          <span v-else class="material-icons checkbox-icon">check_box_outline_blank</span>
          <div class="select-body-line-text">{{ value.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectMultiCheckFiltered',
  props: ['title', 'placeholder', 'list', 'alert', 'action', 'lock'],
  data() {
    return {
      showSelectBody: false,
      stateSearch: undefined
    }
  },
  computed: {
    componentList() {
      if (!this.stateSearch) return this.list
      let resp = []
      this.list.forEach(element => {
        if (element.text.toLowerCase().includes(this.stateSearch.toLowerCase())) resp.push(element)
      });
      return resp
    }
  },
  methods: {
    getlockCheck () {if (this.lock) return 'color: gray'},
    openSelect() { this.showSelectBody = true },
    closeSelect() { this.showSelectBody = false },
    toogleCheck(value) {
      if (this.lock) return
      value.isCheck = !value.isCheck
      let resp = []
      this.list.forEach(element => {
        if (element.isCheck) resp.push(element)
      })
      this.action(resp)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.input-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}

.input-containear {
  border: 1px solid #E5E5E5;
  border-radius: 8px 8px 0 0;
  padding: 12px;
  height: 6vh;
  display: flex;
}

.input-value {
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 1.19em;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}

.input-value:focus {
  border: none;
  outline: none;
}

.input-icon {
  color: #B3B3B3;
  cursor: pointer;
}

.select-body {
  position: absolute;
  background-color: white;
  overflow-y: scroll;
  max-height: 300px;
  z-index: 5;
}

.select-body::-webkit-scrollbar {
  width: 7px;
}

.select-body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  height: 10px;
}

.select-body-line {
  display: flex;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
  flex: 1;
}

.select-body-line:hover {
  background-color: rgba(255, 247, 247, 0.75);
  ;
}

.select-body-line-text {
  font-weight: 300;
  font-size: 1.27em;
  color: #505050;
  margin-left: 5px;
}

.period-width {
  width: 100%;
}

.input-title-alert {
  color: rgb(203, 89, 91);
  font-size: 1em;
}

.checkbox-icon {
  color: var(--primary-color);
}</style>