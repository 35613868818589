<style lang="scss" scoped>
	@import "./new-client.modal";

.input-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-container {
  width: 23vw;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  margin-top: 5px;
  display: flex;
}
.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: #B3B3B3;
}
.input-value:focus {
  border: none;
  outline: none;
}
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2em;
}

@media only screen and (max-width: 700px){
  .main-container{ flex-direction: column;}
  .input-container{width: 100%;}
}

</style>
<template>
    <div class="main-container">
        <div>
            <!-- <label>Nome:<span style="color:red !important">*</span></label> -->
            <div class="input-title" title="Obrigatório">
              Nome
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <!-- <cc-input type="text"
                    id="user-name"
                    v-model="client.manager.name" /> -->
                <div class="input-container">
                  <input type="text" id="user-name" v-model="client.manager.name" class="input-value" placeholder="Digite o nome">
                </div>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div>
            <!-- <label>Login:<span style="color:red !important">*</span></label> -->
            <div class="input-title" title="Obrigatório">
              Login
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <!-- <cc-input type="text"
                    id="user-login"
                    v-model="client.manager.login" /> -->
                <div class="input-container">
                  <input type="text" id="user-login" v-model="client.manager.login" class="input-value" placeholder="Digite o login">
                </div>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div>
            <!-- <label>CPF:<span style="color:red !important">*</span></label> -->
            <div class="input-title">
              CPF
            </div>
            <ValidationProvider v-slot="v">
                <!-- <cc-input
                    v-mask="'###.###.###-###'"
                    type="text"
                    id="user-cpf"
                    v-model="client.manager.cpf" /> -->
                <div class="input-container">
                  <input type="text" id="user-cpf" v-mask="'###.###.###-###'" v-model="client.manager.cpf" class="input-value" placeholder="Digite o CPF">
                </div>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div>
            <!-- <label>Senha:<span style="color:red !important">*</span></label> -->
            <div class="input-title" title="Obrigatório">
              Senha
              <span class="material-icons" style="color: #CB595B;">
                info
              </span>
            </div>
            <ValidationProvider rules="required" v-slot="v">
                <!-- <cc-input
                    type="password"
                    id="user-password"
                    v-model="client.manager.password" /> -->
                <div class="input-container">
                  <input type="password" id="user-password" v-model="client.manager.password" class="input-value" placeholder="Digite a senha">
                </div>
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
    </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
	mixins: [ loaderMixin ],
	props: {
		client: {
			type: Object,
            required: true
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver
   	},
  	data() {
		return {

		};
	},
  	methods: {

	},
	mounted() {
	}
};
</script>
