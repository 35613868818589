import http from "../http";
import Service from "../../Service";

/**
 * @type UserService
 */
export default class UserService extends Service {

    list(params) {
		return http.get(`/admin/management/users`, {
			headers: this.getHeaders(),
            params
		});
	}

}
